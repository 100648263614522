import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import {
    BilledProcedureGridObject,
    BilledProcedureUpdateInput,
    BilledVisitGridObject,
    BilledVisitUpdateInput,
    BillingBatchGridObject,
    BillingBatchObject,
    BillingDocumentGridObject,
    BillingDocumentObject,
    BillingDocumentRefuseInput,
    BillingDocumentRenumberInput,
    BillingErrorsObject,
    BillingExportInput,
    BillingFilesObject,
    BillingGridObject,
    BillingObject,
    CreateBillingInput,
    DeleteBillingInput,
    DeleteInvoiceInput,
    GetBillingBatchInput,
    GetBillingDocumentInput,
    GetBillingErrorsInput,
    GetInvoiceInput,
    InvoiceGridObject,
    InvoiceObject,
    OkObject,
    PdfPrintObject,
    PrepareBillingInput,
    UpdateBillingBatchInput,
    UpdateBillingDocumentInput,
    UpdateInvoiceInput,
} from '@it2go/types';
import {
    billedProcedureGridItemFrag,
    billedVisitGridItemFrag,
    billingBatchFrag,
    billingBatchGridItemFrag,
    billingDocumentFrag,
    billingDocumentGridItemFrag,
    billingErrorsFrag,
    billingFilesFrag,
    billingFrag,
    billingGridItemFrag,
    invoiceFrag,
    invoiceGridItemFrag,
} from './types/billing.types';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from '../modules/content/service/types/filter.types';
import { Observable } from 'rxjs';

@Injectable()
export class BillingGqlService extends GqlService {

    getBillingErrors(input: GetBillingErrorsInput)  {
        return this.query<BillingErrorsObject>(
            `
                query($input: GetBillingErrorsInput!) {
                    billing {
                        listErrors(input: $input) {
                            ${billingErrorsFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getBillings(input: GridInput | null) {
        return this.query<BillingGridObject>(
            `
                query($input: GridInput) {
                    billing {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${billingGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createBilling(input: CreateBillingInput) {
        return this.mutate<BillingFilesObject>(
            `
                mutation($input: CreateBillingInput!) {
                    billing {
                        create(input: $input) {
                            ${billingFilesFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    prepareBilling(input: PrepareBillingInput) {
        return this.mutate<BillingObject>(
            `
                mutation($input: PrepareBillingInput!) {
                    billing {
                        prepare(input: $input) {
                            ${billingFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteBilling(input: DeleteBillingInput) {
        return this.mutate<OkObject>(
            `
                mutation ($input: DeleteBillingInput!) {
                    billing {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        )
    }

    getInvoices(input: GridInput | null) {
        return this.query<InvoiceGridObject>(
            `
                query($input: GridInput) {
                    invoice {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${invoiceGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getInvoice(input: GetInvoiceInput) {
        return this.query<InvoiceObject>(
            `
                query($input: GetInvoiceInput!) {
                    invoice {
                        get(input: $input) {
                            ${invoiceFrag}
                        }
                    }
                }
            `,
            { input },
        )
    }

    updateInvoice(input: UpdateInvoiceInput) {
        return this.mutate<InvoiceObject>(`
                mutation ($input: UpdateInvoiceInput!) {
                    invoice {
                        update(input: $input) {
                            ${invoiceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteInvoice(input: DeleteInvoiceInput) {
        return this.mutate<OkObject>(
            `
                mutation ($input: DeleteInvoiceInput!) {
                    invoice {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getBillingBatchesOfVoucherType(input: GridInput | null) {
        return this.getBillingBatches(input);
    }

    getBillingBatchesOfAmbulantType(input: GridInput | null) {
        return this.getBillingBatches(input);
    }

    getBillingBatchesOfTransportType(input: GridInput | null) {
        return this.getBillingBatches(input);
    }

    getBillingBatches(input: GridInput | null) {
        return this.query<BillingBatchGridObject>(
            `
                query($input: GridInput) {
                    billingBatch {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${billingBatchGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getBillingBatch(input: GetBillingBatchInput) {
        return this.query<InvoiceObject>(
            `
                query($input: GetBillingBatchInput!) {
                    billingBatch {
                        get(input: $input) {
                            ${billingBatchFrag}
                        }
                    }
                }
            `,
            { input },
        )
    }

    updateBillingBatch(input: UpdateBillingBatchInput) {
        return this.mutate<BillingBatchObject>(`
                mutation ($input: UpdateBillingBatchInput!) {
                    billingBatch {
                        update(input: $input) {
                            ${billingBatchFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    renumberBillingDocuments(input: BillingDocumentRenumberInput) {
        return this.mutate<OkObject>(`
                mutation ($input: BillingDocumentRenumberInput!) {
                    billingBatch {
                        renumberBillingDocuments(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getBillingDocuments(input: GridInput | null) {
        return this.query<BillingDocumentGridObject>(
            `
                query($input: GridInput) {
                    billingDocument {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${billingDocumentGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getBillingDocument(input: GetBillingDocumentInput) {
        return this.query<BillingDocumentObject>(
            `
                query($input: GetBillingDocumentInput!) {
                    billingDocument {
                        get(input: $input) {
                            ${billingDocumentFrag}
                        }
                    }
                }
            `,
            { input },
        )
    }

    updateBillingDocument(input: UpdateBillingDocumentInput) {
        return this.mutate<BillingDocumentObject>(`
                mutation ($input: UpdateBillingDocumentInput!) {
                    billingDocument {
                        update(input: $input) {
                            ${billingDocumentFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    refuseBillingDocuments(input: BillingDocumentRefuseInput[]) {
        return this.mutate<OkObject>(`
                mutation ($input: [BillingDocumentRefuseInput!]!) {
                    billingDocument {
                        refuse(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getBilledProcedures(input: GridInput | null) {
        return this.query<BilledProcedureGridObject>(
            `
                query($input: GridInput) {
                    billedProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${billedProcedureGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateBilledProcedures(input: BilledProcedureUpdateInput[]) {
        return this.mutate(
            `
            mutation($input: [BilledProcedureUpdateInput!]!) {
                billedProcedure {
                    updateMany(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    getBilledVisits(input: GridInput | null) {
        return this.query<BilledVisitGridObject>(
            `
                query($input: GridInput) {
                    billedVisit {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${billedVisitGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getPreviouslyBilledVisits(input: GridInput | null) {
        return this.getBilledVisits(input);
    }

    public updateBilledVisits(input: BilledVisitUpdateInput[]) {
        return this.mutate(
            `
            mutation($input: [BilledVisitUpdateInput!]!) {
                billedVisit {
                    updateMany(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public exportToPdf(input: BillingExportInput): Observable<PdfPrintObject> {
        return this.mutate(
            `
                mutation($input: BillingExportInput!) {
                    billing {
                        exportToPdf(input: $input) {
                            fileName
                            pdfBase64
                        }
                    }
                }
            `,
            { input },
        );
    }
}
