import { GqlService } from "../modules/content/service/gql.service";
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import { anamnesisFrag } from './types/anamnesis.types';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import { AnamnesisUpdateInput } from '@libs/types/src/services/input-type/anamnesis/anamnesis.update-input';
import { AnamnesisCreateInput } from '@libs/types/src/services/input-type/anamnesis/anamnesis.create-input';

@Injectable()
export class AnamnesisGqlService extends GqlService {

    constructor(
        apollo: Apollo,
    ) {
        super(apollo);
    }

    public getAnamneses(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    anamnesis {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${anamnesisFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createAnamnesis(input: AnamnesisCreateInput) {
        return this.mutate(
            `
                mutation($input: AnamnesisCreateInput!) {
                    anamnesis {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateAnamnesis(input: AnamnesisUpdateInput) {
        return this.mutate(
            `
                mutation($input: AnamnesisUpdateInput!) {
                    anamnesis {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteAnamnesis(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    anamnesis {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
