import { GridInput } from '@it2go/data-grid';
import { RegionGridObject } from '@it2go/types';
import { filterFrag } from '../modules/content/service/types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import { regionFrag } from './types/region.types';

@Injectable()
export class RegionGqlService extends GqlService {
    getRegions(input: GridInput | null) {
        return this.query<RegionGridObject>(`
            query($input: GridInput) {
                region {
                    filter(input: $input) {
                        ${filterFrag}
                        items {
                            ${regionFrag}
                        }
                    }
                }
            }
        `,
            { input },
        );
    }
}
