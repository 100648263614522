import { Injectable } from '@angular/core';
import { GqlService } from '../modules/content/service/gql.service';
import {
    ServiceWorkgroupCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-workgroup.create-input';
import {
    ServiceWorkgroupUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-workgroup.update-input';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import {
    ServiceOperationalCapacityCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-operational-capacity.create-input';
import {
    ServiceOperationalCapacityUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-operational-capacity.update-input';
import { filterFrag } from './types/filter.types';
import { GridInput } from '@it2go/data-grid';
import {
    serviceDoctorRecordTypeItemFrag,
    serviceGroupItemFrag, serviceLocationItemFrag,
    serviceOperationalCapacityItemFrag,
    serviceSpecialistContactTypeItemFrag,
    serviceSpecialistWorkTypeItemFrag,
    serviceWorkgroupItemFrag,
} from './types/service-settings.types';
import {
    ServiceDoctorRecordTypeUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-doctor-record-type.update-input';
import {
    ServiceDoctorRecordTypeCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-doctor-record-type.create-input';
import { ServiceGroupUpdateInput } from '@libs/types/src/services/input-type/services/service-group.update-input';
import { ServiceGroupCreateInput } from '@libs/types/src/services/input-type/services/service-group.create-input';
import {
    ServiceSpecialistRecordWorkTypeCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-work-type.create-input';
import {
    ServiceSpecialistRecordWorkTypeUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-work-type.update-input';
import {
    ServiceSpecialistRecordContactTypeUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-contact-type.update-input';
import {
    ServiceSpecialistRecordContactTypeCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-contact-type.create-input';
import { ServiceLocationCreateInput } from '@libs/types/src/services/input-type/services/service-location.create-input';
import { ServiceLocationUpdateInput } from '@libs/types/src/services/input-type/services/service-location.update-input';
import { ServiceSettingsUpdateInput } from '@libs/types/src/services/input-type/services/service-settings.update-input';

@Injectable()
export class ServiceSettingsGqlService extends GqlService {

    public getServiceWorkgroups(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceWorkgroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceWorkgroupItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceWorkgroup(input: ServiceWorkgroupCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceWorkgroupCreateInput!) {
                    serviceWorkgroup {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceWorkgroup(input: ServiceWorkgroupUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceWorkgroupUpdateInput!) {
                    serviceWorkgroup {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceWorkgroup(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceWorkgroup {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceOperationalCapacities(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceOperationalCapacity {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceOperationalCapacityItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceOperationalCapacity(input: ServiceOperationalCapacityCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceOperationalCapacityCreateInput!) {
                    serviceOperationalCapacity {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceOperationalCapacity(input: ServiceOperationalCapacityUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceOperationalCapacityUpdateInput!) {
                    serviceOperationalCapacity {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceOperationalCapacity(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceOperationalCapacity {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceDoctorRecordTypes(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceDoctorRecordType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceDoctorRecordTypeItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceDoctorRecordType(input: ServiceDoctorRecordTypeCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceDoctorRecordTypeCreateInput!) {
                    serviceDoctorRecordType {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceDoctorRecordType(input: ServiceDoctorRecordTypeUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceDoctorRecordTypeUpdateInput!) {
                    serviceDoctorRecordType {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceDoctorRecordType(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceDoctorRecordType {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceGroups(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceGroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceGroupItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceGroup(input: ServiceGroupCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceGroupCreateInput!) {
                    serviceGroup {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceGroup(input: ServiceGroupUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceGroupUpdateInput!) {
                    serviceGroup {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceGroup(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceGroup {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceSpecialistRecordWorkTypes(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceSpecialistRecordWorkType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceSpecialistWorkTypeItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceSpecialistRecordWorkType(input: ServiceSpecialistRecordWorkTypeCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordWorkTypeCreateInput!) {
                    serviceSpecialistRecordWorkType {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceSpecialistRecordWorkType(input: ServiceSpecialistRecordWorkTypeUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordWorkTypeUpdateInput!) {
                    serviceSpecialistRecordWorkType {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceSpecialistRecordWorkType(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceSpecialistRecordWorkType {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceSpecialistRecordContactTypes(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceSpecialistRecordContactType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceSpecialistContactTypeItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceSpecialistRecordContactType(input: ServiceSpecialistRecordContactTypeCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordContactTypeCreateInput!) {
                    serviceSpecialistRecordContactType {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceSpecialistRecordContactType(input: ServiceSpecialistRecordContactTypeUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordContactTypeUpdateInput!) {
                    serviceSpecialistRecordContactType {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceSpecialistRecordContactType(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceSpecialistRecordContactType {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceLocations(input: GridInput) {
        return this.query(
            `
            query($input: GridInput) {
                serviceLocation {
                    filter(input: $input) {
                        ${filterFrag}
                        items {
                            ${serviceLocationItemFrag}
                        }
                    }
                }
            }
        `,
            { input },
        );
    }

    public createServiceLocation(input: ServiceLocationCreateInput) {
        return this.mutate(
            `
            mutation($input: ServiceLocationCreateInput!) {
                serviceLocation {
                    create(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public updateServiceLocation(input: ServiceLocationUpdateInput) {
        return this.mutate(
            `
            mutation($input: ServiceLocationUpdateInput!) {
                serviceLocation {
                    update(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public deleteServiceLocation(input: IdInput) {
        return this.mutate(
            `
            mutation($input: IdInput!) {
                serviceLocation {
                    delete(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public getServiceSettings(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceSettings {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                id
                                key
                                data
                                serviceId
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceSettings(input: ServiceSettingsUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSettingsUpdateInput!) {
                    serviceSettings {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
