import { diagnosisGridItemFrag } from './medication.types';
import { medicalProcedureFrag } from './medical-procedure.types';

export const anamnesisFrag = `
    id
    dateFrom
    createdAt
    updatedAt
    dateTo
    bloodType
    anamnesis
    diagnosis {
        ${diagnosisGridItemFrag}
    }
    isHiv
    isHbsag
    isRwe
    alergies
    operation
    symptoms
    invasives
    importantNote
    note
    socialAnamnesis
    warning
    vaccine
    implants
    medication
    wishes
    folderId
    secondaryDiagnoses {
        ${diagnosisGridItemFrag}
    }
    medicalProcedures {
        ${medicalProcedureFrag}
    }
`;
