import { Injectable } from '@angular/core';
import { GqlService } from '../modules/content/service/gql.service';
import { VzpValidateRcInput } from '@libs/types/src/services/input-type/vzp/vzp.validate-rc-input';
import { VzpValidateRcObject } from '@libs/types/src/services/object-type/vzp/vzp.validate-rc-object';

@Injectable()
export class VzpGqlService extends GqlService {

    public validateRc(input: VzpValidateRcInput) {
        return this.query<VzpValidateRcObject>(
            `
                query($input: VzpValidateRcInput!) {
                    vzp {
                        validateRc(input: $input) {
                            insured
                            code
                        }
                    }
                }
            `,
            { input },
        );
    }

}
