import { Injectable } from '@angular/core';
import {
    FolderFormCreateInput,
    FolderFormDataCheckExistingInput,
    FolderFormDataCreateInput,
    FolderFormDataDeleteInput,
    FolderFormDataGetLastInput,
    FolderFormDataObjectDepre,
    FolderFormDataPrintInput,
    FolderFormDataPrintObject,
    FolderFormDataUpdateInput,
    FolderFormDeleteInput,
    FolderFormListInput,
    FolderFormUpdateInput,
    FolderGridObject,
    GetFolderInput,
    OkObject,
    PersonGridObject,
} from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { FolderTerminateInput } from '@libs/types/src/services/input-type/folder/folder.terminate-input';
import {
    folderFormDataFrag,
    folderFormDataLastFrag,
    folderFormFrag as oldFolderFormFrag,
    folderGridItemFrag,
    folderMedicalReportFrag,
    folderStatusHistoryFrag,
} from './types/folder.types';
import { filterFrag } from './types/filter.types';
import {
    FolderStatusHistoryGridObject,
} from '@libs/types/src/services/object-type/folder/folder-status-history.object';
import { GridInput } from '@it2go/data-grid';
import {
    FolderTerminationCheckObject,
} from '@libs/types/src/services/object-type/folder/folder.termination-check-object';
import { FolderCloseVouchersInput } from '@libs/types/src/services/input-type/folder/folder.close-vouchers-input';
import {
    FolderMedicalReportCreateInput,
} from '@libs/types/src/services/input-type/folder/folder-medical-report.create-input';
import {
    FolderMedicalReportGridObject,
} from '@libs/types/src/services/object-type/folder/folder-medical-report.object';
import {
    FolderMedicalReportDeleteInput,
} from '@libs/types/src/services/input-type/folder/folder-medical-report.delete-input';
import { FolderPrintObject } from '@libs/types/src/services/object-type/folder/folder-print-object';
import { folderFormOnlyFrag } from './types/form.types';
import { FolderFormObject } from '@libs/types/src/services/object-type/form/folder-form.object';
import { FolderFormDataObject } from '@libs/types/src/services/object-type/form/folder-form-data.object';

@Injectable()
export class FolderService extends GqlService {

    public terminateFolder(input: FolderTerminateInput) {
        return this.mutate<PersonGridObject>(
            `
                mutation($input: FolderTerminateInput!) {
                    folder {
                        terminate(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderStatuses(input: GridInput | null = null) {
        return this.query<FolderStatusHistoryGridObject>(
            `
                query($input: GridInput) {
                    folder {
                        filterStatuses(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderStatusHistoryFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getSearchFolder(input: GridInput | null = null) {
        return this.query<FolderGridObject>(
            `
                query($input: GridInput) {
                    folder {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getCheckTermination(input: GetFolderInput) {
        return this.query<FolderTerminationCheckObject>(
            `
                query($input: GetFolderInput!) {
                    folder {
                        checkTermination(input: $input) {
                            voucherIds
                        }
                    }
                }
            `,
            { input },
        );
    }

    public closeVouchers(input: FolderCloseVouchersInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderCloseVouchersInput!) {
                    folder {
                        closeVouchers(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderForms(input: FolderFormListInput) {
        return this.query<FolderFormListInput>(
            `
                query($input: FolderFormListInput!) {
                    folderForm {
                        listForFolder(input: $input) {
                            ${oldFolderFormFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderForm(input: FolderFormCreateInput) {
        return this.mutate<FolderFormObject>(
            `
                mutation($input: FolderFormCreateInput!) {
                    folderForm {
                        create(input: $input) {
                            ${folderFormOnlyFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderForm(input: FolderFormUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderFormUpdateInput!) {
                    folderForm {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderForm(input: FolderFormDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderFormDeleteInput!) {
                    folderForm {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getLastFormData(input: FolderFormDataGetLastInput) {
        return this.query<FolderFormDataObjectDepre>(
            `
                query($input: FolderFormDataGetLastInput!) {
                    folderForm {
                        getLastFormData(input: $input) {
                            ${folderFormDataLastFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderFormData(input: FolderFormDataCreateInput) {
        return this.mutate<FolderFormDataObject>(
            `
                mutation($input: FolderFormDataCreateInput!) {
                    folderForm {
                        createFormData(input: $input) {
                            id
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderFormData(input: FolderFormDataUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderFormDataUpdateInput!) {
                    folderForm {
                        updateFormData(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderFormData(input: FolderFormDataDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderFormDataDeleteInput!) {
                    folderForm {
                        deleteFormData(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public checkFolderFormDataExistingAtDate(input: FolderFormDataCheckExistingInput) {
        return this.query<FolderFormDataObjectDepre[]>(
            `
                query($input: FolderFormDataCheckExistingInput!) {
                    folderForm {
                        checkFolderFormDataExistingAtDate(input: $input) {
                            ${folderFormDataFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public printFolderFormData(input: FolderFormDataPrintInput) {
        return this.mutate<FolderFormDataPrintObject>(
            `
                mutation($input: FolderFormDataPrintInput!) {
                    folderForm {
                        printFormData(input: $input) {
                            fileName
                            pdfBase64
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderMedicalReport(input: FolderMedicalReportCreateInput) {
        return this.mutate<FolderPrintObject>(
            `
                mutation($input: FolderMedicalReportCreateInput!) {
                    folderMedicalReport {
                        create(input: $input) {
                            fileName
                            pdfBase64
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderMedicalReports(input: GridInput | null) {
        return this.query<FolderMedicalReportGridObject>(
            `
                query($input: GridInput) {
                    folderMedicalReport {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderMedicalReportFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderMedicalReport(input: FolderMedicalReportDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderMedicalReportDeleteInput!) {
                    folderMedicalReport {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }
}
