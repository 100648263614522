export const formGridItemFrag = `
    id
    name
    description
    abbreviation
    uniqueIdentifier
    inExpertises
    enabledInServices
    enabledInServiceTypes
`;

export const formFrag = `
    id
    name
    description
    abbreviation
    formFields {
        id
        name
        type
        label
        icon
        width
    }
`;