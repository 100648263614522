import { APOLLO_OPTIONS } from 'apollo-angular';
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache';
import { getOperationDefinition } from '@apollo/client/utilities';
import { ApolloLink, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { contentGlobalErrors } from '../../content/content.error';
import { pageWithoutLayout } from '../../../app.utils';

const GRAPHQL_ENDPOINT = '/graphql';

export const apolloLinkProvider = {
    provide: APOLLO_OPTIONS,
    useFactory() {
        const auth0Link = new ApolloLink((operation, forward) => {
            operation.setContext({
                // more modern syntax once batching link gets updated
                // headers: new HttpHeaders().set(
                //     'Authorization',
                //     `Bearer ${token}`,
                // ),
                headers: {
                    // handle on gateway? use store?
                    selectedOrganizationId: localStorage.getItem('selectedOrganizationId') || '',
                },
            });
            return forward(operation);
        });

        const batch = auth0Link.concat(
            new BatchHttpLink({ uri: GRAPHQL_ENDPOINT, batchMax: 1 }),
        );

        const hostName = window.location.host;
        const isLocalhost = hostName === 'localhost:4200';
        const isSecure = window.location.protocol === 'https:';
        const wsProtocol = isSecure ? 'wss' : 'ws';
        const ws = new GraphQLWsLink(
            createClient({
                url: `${wsProtocol}://${
                    isLocalhost ? 'localhost:1357' : hostName
                }${GRAPHQL_ENDPOINT}`,
            }),
        );

        const link = split(
            ({ query }) => {
                const definition = getOperationDefinition(query);
                if (!definition) {
                    return false;
                }
                const { kind, operation } = definition;
                return (
                    kind === 'OperationDefinition' &&
                    operation === 'subscription'
                );
            },
            ws,
            batch,
        );

        const apolloLinkWithAuth = onError(
            ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    if (graphQLErrors?.[0]?.message == 'Unauthorized') {
                        if (!pageWithoutLayout(window.location.href)) {
                            window.location.href = 'login';
                        }
                    }
                    contentGlobalErrors.next(graphQLErrors?.[0]?.message || '');
                } else if (networkError) {
                    contentGlobalErrors.next(
                        // @ts-expect-error - networkError is not typed correctly
                        networkError.error?.errors?.[0]?.message || networkError.message,
                    );
                }
            },
        ).concat(link);

        const highLevelObjectStrategy = {
            keyArgs: false,
            merge(
                existing: unknown,
                incoming: unknown,
                { mergeObjects }: { mergeObjects: any },
            ) {
                return mergeObjects(existing, incoming);
            },
        } as const;

        return {
            cache: new InMemoryCache({
                dataIdFromObject(responseObject) {
                    switch (responseObject.__typename) {
                        case 'CollectionStatisticsObject':
                            return `CollectionStatisticsObject:${responseObject['id']}${responseObject['donationFormId']}${responseObject['from']}`;
                        case 'PersonObject':
                            return responseObject['relationId']
                                ? `Person:${responseObject['relationId']}`
                                : `Person:${responseObject['id']}`;
                        default:
                            return defaultDataIdFromObject(responseObject);
                    }
                },
                typePolicies: {
                    Query: {
                        fields: {
                            authentication: highLevelObjectStrategy,
                            center: highLevelObjectStrategy,
                            compensation: highLevelObjectStrategy,
                            donation: highLevelObjectStrategy,
                            donationReport: highLevelObjectStrategy,
                            donor: highLevelObjectStrategy,
                            donorActivity: highLevelObjectStrategy,
                            employmentType: highLevelObjectStrategy,
                            epicrisis: highLevelObjectStrategy,
                            fileCategory: highLevelObjectStrategy,
                            folder: highLevelObjectStrategy,
                            folderMedication: highLevelObjectStrategy,
                            form: highLevelObjectStrategy,
                            group: highLevelObjectStrategy,
                            medicalProcedure: highLevelObjectStrategy,
                            medication: highLevelObjectStrategy,
                            notifications: highLevelObjectStrategy,
                            organization: highLevelObjectStrategy,
                            organizationPerson: highLevelObjectStrategy,
                            person: highLevelObjectStrategy,
                            personContact: highLevelObjectStrategy,
                            rule: highLevelObjectStrategy,
                            service: highLevelObjectStrategy,
                            serviceRecord: highLevelObjectStrategy,
                            systemSettings: highLevelObjectStrategy,
                            template: highLevelObjectStrategy,
                            user: highLevelObjectStrategy,
                            userTable: highLevelObjectStrategy,
                            visitHistory: highLevelObjectStrategy,
                            voucher: highLevelObjectStrategy,
                            work: highLevelObjectStrategy,
                        },
                    },
                },
            }),
            link: apolloLinkWithAuth,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'network-only',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        };
    },
    deps: [],
};
