import { GridInput } from '@it2go/data-grid';
import { TerritorialVzpWorkplaceGridObject } from '@it2go/types';
import { filterFrag } from '../modules/content/service/types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import { territorialVzpWorkplaceGridItemFrag } from './types/territorial-vzp-workplace.types';

@Injectable()
export class TerritorialVzpWorkplaceGqlService extends GqlService {
    getTerritorialVzpWorkplaces(input: GridInput | null) {
        return this.query<TerritorialVzpWorkplaceGridObject>(`
            query($input: GridInput) {
                territorialVzpWorkplace {
                    filter(input: $input) {
                        ${filterFrag}
                        items {
                            ${territorialVzpWorkplaceGridItemFrag}
                        }
                    }
                }
            }
        `,
            { input },
        );
    }
}
