import { GridInput } from '@it2go/data-grid';
import { BankGridObject } from '@it2go/types';
import { filterFrag } from '../modules/content/service/types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import { bankFrag } from './types/bank.types';

@Injectable()
export class BankGqlService extends GqlService {
    getBankCodes(input: GridInput | null) {
        return this.query<BankGridObject>(`
            query($input: GridInput) {
                bank {
                    filter(input: $input) {
                        ${filterFrag}
                        items {
                            ${bankFrag}
                        }
                    }
                }
            }
        `,
            { input },
        );
    }
}
