import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { GroupGridObject, GroupObject, OkObject } from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { filterFrag } from './types/filter.types';
import { groupGridItemFrag, ruleGridItemFrag } from './types/acl.types';
import { OrganizationIdInput } from '@libs/types/src/organization/input-type/organization/organization.id-input';
import { GroupCreateInput } from '@libs/types/src/user/input-type/group/group.create-input';
import { GroupUpdateInput } from '@libs/types/src/user/input-type/group/group.update-input';
import { IdInput } from '@libs/types/src/common/input-type/id.input';

@Injectable()
export class AclService extends GqlService {

    getGroups(input: GridInput | null = null) {
        return this.query<GroupGridObject>(
            `
                query($input: GridInput) {
                    group {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${groupGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public allAvailableGroups(input: OrganizationIdInput) {
        return this.query<GroupObject[]>(
            `
                query($input: OrganizationIdInput!) {
                    group {
                        allAvailable(input: $input) {
                            ${groupGridItemFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createGroup(input: GroupCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: GroupCreateInput!) {
                    group {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateGroup(input: GroupUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: GroupUpdateInput!) {
                    group {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteGroup(input: IdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    group {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
