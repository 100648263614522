import { GridInput } from '@it2go/data-grid';
import { InsuranceCompanyGridObject } from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import { insuranceCompanyFrag } from './types/insurance-company.types';

@Injectable()
export class InsuranceCompanyGqlService extends GqlService {

    getInsuranceCompanies(input: GridInput | null) {
        return this.query<InsuranceCompanyGridObject>(
            `
                query($input: GridInput) {
                    insuranceCompany {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${insuranceCompanyFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }
}
